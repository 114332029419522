import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import PageHeader from '../../../../components/PageHeader'
import ProtectedComponent from '../../../../components/ProtectedComponent'
import { Context } from '../../../../context'
import { CONSULTANT_ROLE, SAURON_ROLE } from '../../../../userRoles'

import ApplicantsTab from './components/ApplicantsTab'
import VacancyInfoTab from './components/VacancyInfoTab'
import VideoVotingTab from './components/VideoVotingTab'
import { APPLICATIONS, VACANCY } from './queries'

import styles from './styles.module.css'

const statusEnum = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '80px',
      width: '100%',
      backgroundColor: '#161616',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#161616',
    fontWeight: 900,
    fontSize: '18px',
    '&:focus': {
      opacity: 1,
    },
    letterSpacing: '2px',
  },
}))(props => <Tab disableRipple {...props} />)

const Vacancy = () => {
  const [vacancy, setVacancy] = useState(null)
  const [applications, setApplications] = useState({ data: [], meta: {} })
  const [pageTitle, setPageTitle] = useState('Mis vacantes')
  const [isRefetchingApp, setIsRefetchingApp] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0)
  const [filters, setFilters] = useState({
    applicationStatus: 'ALL',
    applierName: '',
    applierExpectedSalaryMax: null,
  })
  const [pagination, setPagination] = useState({
    page: 1,
    take: 5,
    sortField: 'createdAt',
    sortOrder: 'DESC',
  })

  const history = useHistory()
  const { vacancyId } = useParams()
  const { VISIBLE } = statusEnum

  const { loading, error, data, refetch } = useQuery(VACANCY, {
    variables: { _id: vacancyId },
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingApps,
    error: errorApps,
    data: dataApps,
    refetch: refetchApps,
  } = useQuery(APPLICATIONS, {
    variables: {
      vacancyId,
      pagination,
      filters,
    },
    fetchPolicy: 'network-only',
  })

  const userInfo = useContext(Context)

  const formatDate = applications => {
    dayjs.extend(customParseFormat)
    return applications.map(el => ({
      ...el,
      createdAt: dayjs(el.createdAt).format('DD-MM-YY'),
    }))
  }

  const formatDateVacancy = vacancyItem => {
    dayjs.extend(customParseFormat)
    return {
      ...vacancyItem,
      createdAt: dayjs(vacancyItem.createdAt).format('DD-MM-YY'),
      updatedAt: dayjs(vacancyItem.updatedAt).format('DD-MM-YY'),
    }
  }

  useEffect(() => {
    if (data?.vacancy) {
      const formattedVacancy = formatDateVacancy(data.vacancy)
      setPageTitle(formattedVacancy.title)
      setVacancy(formattedVacancy)
    }
  }, [data])

  useEffect(() => {
    if (dataApps?.applications) {
      const formattedApps = formatDate(dataApps.applications.data || [])
      setApplications({
        data: formattedApps,
        meta: dataApps.applications.meta,
      })
    }
  }, [dataApps])

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const handlePageChange = newPage => {
    const newPagination = { ...pagination, page: newPage }
    setPagination(newPagination)
  }

  const handleItemsPerPageChange = newTake => {
    const newPagination = { ...pagination, take: newTake, page: 1 }
    setPagination(newPagination)
  }

  const handleReloadApps = async () => {
    setIsRefetchingApp(true)
    await refetchApps()
    setIsRefetchingApp(false)
  }

  if (error || errorApps) return <div>Error al cargar los datos</div>

  return (
    <>
      {userInfo.role === SAURON_ROLE || userInfo.role === CONSULTANT_ROLE ? (
        <PageHeader
          title={pageTitle}
          type="vacancy"
          button={{
            text: 'Ranking',
            handleClick: () =>
              history.push(`/admin/vacancies/ranking/${vacancyId}`),
          }}
        />
      ) : (
        <PageHeader title={pageTitle} type="vacancy" />
      )}

      <Container>
        <Grid className={styles.vacancyDates}>
          <ProtectedComponent allowedRoles={[CONSULTANT_ROLE, SAURON_ROLE]}>
            <Typography className={styles.vacancyDatesCreated}>
              Publicado el <span>{vacancy?.createdAt}</span>
            </Typography>
            <Typography
              className={
                vacancy?.status === VISIBLE
                  ? styles.vacancyDatesClosedInactive
                  : styles.vacancyDatesClosed
              }>
              Cerrada el <span>{vacancy?.updatedAt}</span>
            </Typography>
          </ProtectedComponent>
        </Grid>
        <StyledTabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          style={{ marginBottom: 20 }}>
          <StyledTab label="Postulantes" />
          {(userInfo.role === SAURON_ROLE ||
            userInfo.role === CONSULTANT_ROLE) && <StyledTab label="Videos" />}
          <StyledTab label="Publicación" />
        </StyledTabs>
        {selectedTab === 0 ? (
          <ApplicantsTab
            applications={applications.data}
            handleReload={async () => await refetch()}
            handleAppReload={async () => await handleReloadApps()}
            questions={vacancy ? vacancy.questions : []}
            vacancyStatus={vacancy?.status}
            meta={applications.meta}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
            filters={filters}
            setFilters={setFilters}
            loading={loadingApps || isRefetchingApp}
            setPagination={setPagination}
          />
        ) : selectedTab === 1 &&
          (userInfo.role === SAURON_ROLE ||
            userInfo.role === CONSULTANT_ROLE) ? (
          <VideoVotingTab
            data={vacancy?.applicationsToVote || []}
            reload={refetch}
            reloadApp={refetchApps}
            isLoading={loadingApps}
          />
        ) : (
          <VacancyInfoTab vacancy={vacancy} loading={loading} />
        )}
      </Container>
    </>
  )
}

export default Vacancy
