import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import styles from './styles.module.css'

const offset = 3

const OldPagination = ({
  currentPage,
  search,
  totalPages,
  hasNextPage,
  hasPreviousPage,
}) => {
  const renderPages = pages => {
    return pages.map((page, index) => {
      return (
        <div
          className={styles.uniqPage}
          key={index.toString()}
          onClick={() => {
            search(page)
          }}>
          <div className={styles.page}>{page}</div>
        </div>
      )
    })
  }

  const renderCurrentPage = () => {
    return (
      <div className={styles.currentPage}>
        <strong>{currentPage}</strong>
      </div>
    )
  }

  const renderPreviousPages = () => {
    const pages = []
    for (let i = currentPage - offset; i < currentPage; i++)
      if (i > 0) pages.push(i)

    return renderPages(pages)
  }

  const renderNextPages = () => {
    const pages = []
    for (let i = currentPage + offset; i > currentPage; i--)
      if (i <= totalPages) pages.unshift(i)

    return renderPages(pages)
  }

  const renderLeft = () => {
    return (
      <div
        className={hasPreviousPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasPreviousPage && search(currentPage - 1)}>
        <MdKeyboardArrowLeft size={25} />
      </div>
    )
  }

  const renderFirstPage = () => {
    return (
      <div
        className={hasPreviousPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() =>
          hasPreviousPage && search(currentPage - (currentPage - 1))
        }>
        <div className={styles.contentDoubleArrow}>
          <MdKeyboardArrowLeft size={25} />
          <div style={{ marginInline: -10 }} />
          <MdKeyboardArrowLeft size={25} />
          <div style={{ marginRight: -5 }} />
        </div>
      </div>
    )
  }
  const renderLastPage = () => {
    return (
      <div
        className={hasNextPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasNextPage && search(totalPages)}>
        <div className={styles.contentDoubleArrow}>
          <MdKeyboardArrowRight size={25} />
          <div style={{ marginInline: -10 }} />
          <MdKeyboardArrowRight size={25} />
          <div style={{ marginLeft: -5 }} />
        </div>
      </div>
    )
  }

  const renderRight = () => {
    return (
      <div
        className={hasNextPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasNextPage && search(currentPage + 1)}>
        <MdKeyboardArrowRight size={25} />
      </div>
    )
  }

  const pagination = () => {
    return (
      <div className={styles.pagination}>
        <div className={styles.content}>
          <div className={styles.arrow}>{renderFirstPage()}</div>
          <div className={styles.arrow}>{renderLeft()}</div>
          <div className={styles.leftPages}>{renderPreviousPages()}</div>
          <div className={styles.centerPage}>{renderCurrentPage()}</div>
          <div className={styles.rightPages}>{renderNextPages()}</div>
          <div className={styles.arrow}>{renderRight()}</div>
          <div className={styles.arrow}>{renderLastPage()}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div>{pagination()}</div>
    </div>
  )
}

export default OldPagination