import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import SelectOptions from '../../../../../../../../components/SelectOptions'
import { useDebounce } from '../../../../../../../../hooks/useDebounce'

import styles from './styles.module.css'

const LudwikaOptions = [
  {
    value: 'agreeableness',
    label: 'Afabilidad',
  },
  {
    value: 'openness',
    label: 'Apertura',
  },
  {
    value: 'conscientiousness',
    label: 'Prolijidad',
  },
  {
    value: 'extraversion',
    label: 'Extroversión',
  },
  {
    value: 'neuroticism',
    label: 'Estabilidad',
  },
]

const Options = ({
  take,
  filters,
  handleFilterChange,
  statusOptions,
  handleUnlink,
  unlinkButtonDisabled,
  isUnlinkBtnVisible,
  onItemsPerPageChange,
}) => {
  const [searchValue, setSearchValue] = useState(filters.applierName)
  const [rentValue, setRentValue] = useState(filters.applierExpectedSalaryMax)
  const debouncedSearchValue = useDebounce(searchValue)
  const debouncedRentValue = useDebounce(rentValue)

  useEffect(() => {
    handleFilterChange('applierName', debouncedSearchValue)
  }, [debouncedSearchValue, handleFilterChange])

  useEffect(() => {
    handleFilterChange('applierExpectedSalaryMax', debouncedRentValue)
  }, [debouncedRentValue, handleFilterChange])

  const renderVacancies = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Estados de Candidatos</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.applicationStatus}
          name="applicationStatus"
          fullWidth
          onChange={event =>
            handleFilterChange(event.target.name, event.target.value)
          }>
          <MenuItem value="ALL">Ver todos</MenuItem>
          {statusOptions.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const ludwikaOrder = () => (
    <Grid item xs={5} sm={2}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="role-select-label">Orden Ludwika</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={filters.ludwikaOrderField}
          name="ludwikaOrderField"
          fullWidth
          onChange={event =>
            handleFilterChange(event.target.name, event.target.value)
          }>
          <MenuItem value={undefined}>Sin orden</MenuItem>
          {LudwikaOptions.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )

  const renderRent = () => (
    <Grid item xs={6} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que pidan un monto igual o menor al monto que escribas"
        placement="top">
        <FormControl component="fieldset">
          <TextField
            className="noSpinner"
            label="Renta Máxima"
            type="number"
            placeholder="Ej. 1200000"
            variant="outlined"
            name="applierExpectedSalaryMax"
            value={rentValue || ''}
            onChange={event => setRentValue(Number(event.target.value))}
          />
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={12} sm={4}>
      <FormControl component="fieldset" className={styles.search}>
        <TextField
          id="outlined-basic"
          label="Búsqueda por nombre"
          variant="outlined"
          name="applierName"
          fullWidth
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )

  return (
    <div className={styles.optionsContainer}>
      {isUnlinkBtnVisible && (
        <div className={styles.button}>
          <Button
            className={styles.unlinkBtn}
            onClick={handleUnlink}
            disabled={unlinkButtonDisabled}>
            Desvincular
          </Button>
        </div>
      )}
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        {renderVacancies()}
        {renderRent()}
        {renderSearch()}
        {ludwikaOrder()}
        <SelectOptions
          value={take}
          onChange={onItemsPerPageChange}
          name="Cantidad postulantes"
        />
      </Grid>
    </div>
  )
}

export default Options
