import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { gql, useMutation } from '@apollo/client'
import { Grid, Paper, Typography } from '@material-ui/core'

import OldPagination from '../../../../../../components/OldPagination'
import { Context } from '../../../../../../context'
import { getPageItems } from '../../../../../../helpers/paginated'

import SelectVideoVacancy from './components/SelectVideoVacancy'
import VotesOptions from './components/VotesOptions'

import styles from './styles.module.css'

const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN
const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($_id: ID!, $input: UpdateApplicationInput!) {
    updateApplication(_id: $_id, input: $input) {
      _id
    }
  }
`

const PLAYER_CONFIG = {
  file: {
    attributes: {
      controlslist: 'nodownload',
    },
  },
}

const ApplicantsGrid = ({ data, reload, isLoading, refetchApps }) => {
  const userInfo = useContext(Context)
  const [elements, setElements] = useState({})
  const [previousItems, setPreviousItems] = useState(null)
  const [maxItems, setMaxItems] = useState(5)
  const [updateApplication, { loading }] = useMutation(UPDATE_APPLICATION)

  const handleVote = async (vote, videoResults, applicationId) => {
    const votes = videoResults.map(({ vote, userid }) => ({ vote, userid }))
    const newVote = {
      vote,
      userid: userInfo._id,
    }

    votes.push(newVote)

    try {
      await updateApplication({
        variables: {
          _id: applicationId,
          input: {
            videoResults: votes,
          },
        },
      })

      reload()
      refetchApps()
    } catch (error) {
      console.log(error)
    }
  }

  const setPage = useCallback(
    (page = 1) => {
      if (!data) return
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(data, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [data, maxItems],
  )

  useEffect(() => {
    if (previousItems !== maxItems) {
      setPreviousItems(maxItems)
      setPage()
    } else setPage(elements.currentPage)
  }, [data, elements.currentPage, maxItems, previousItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <OldPagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  const handleSelectChange = newValue => {
    setMaxItems(newValue)
  }

  return (
    <>
      {elements.exists && (
        <SelectVideoVacancy value={maxItems} onChange={handleSelectChange} />
      )}
      <Grid container spacing={3}>
        {elements.exists ? (
          elements.items.map(candidate => (
            <Grid
              className={styles.mainContentCard}
              key={candidate._id}
              item
              xs={12}
              sm={6}>
              <Paper elevation={3} square className={styles.boxItems}>
                <div className={styles.textName}>
                  <Typography
                    className={styles.text}
                    variant="h4"
                    component="h3">
                    {candidate.applierName}
                  </Typography>
                </div>
                <div className={styles.videoPlayer}>
                  <ReactPlayer
                    url={`${CDN_URL}/${candidate?.video}`}
                    config={PLAYER_CONFIG}
                    height="400px"
                    width="95%"
                    controls
                  />
                </div>
                <div className={styles.containerButtons}>
                  <VotesOptions
                    vote={vote =>
                      handleVote(vote, candidate.videoResults, candidate._id)
                    }
                    optionsDisabled={
                      !(
                        candidate.videoResults.filter(
                          vote => vote.userid === userInfo._id,
                        ).length < 2
                      )
                    }
                    loading={loading || isLoading}
                    lastVote={[...candidate.videoResults]
                      .reverse()
                      .find(vote => vote.userid === userInfo._id)}
                  />
                </div>
              </Paper>
            </Grid>
          ))
        ) : (
          <Typography className={styles.notFoundText}>
            No se encontraron videos
          </Typography>
        )}
      </Grid>
      {elements.exists && renderPagination()}
    </>
  )
}

export default ApplicantsGrid
