import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { get } from 'lodash'
import isEmail from 'validator/lib/isEmail'

import ModalMessage from '../../../components/MessageModal'
import PageHeader from '../../../components/PageHeader'
import PrivacyModal from '../../../components/PrivacyModal'
import LinearWithValueLabel from '../../../components/ProgressBar/ProgressBarWithLaber'
import useApplicationUpload from '../../../hooks/useApplicationUpload'

import ApplicationForm from './components/ApplicationForm'

import styles from './styles.module.css'

const VACANCY = gql`
  query Vacancy($_id: ID!) {
    vacancy(_id: $_id) {
      title
      maxIncome
      description
      questions {
        _id
        text
        type
        options
      }
      applications {
        applierPhone
        applierEmail
      }
    }
  }
`

const CREATE_APPLICATION = gql`
  mutation CreateApplication($input: ApplicationInput!) {
    createApplication(input: $input) {
      _id
    }
  }
`

const fileTypes = {
  RESUME: 'RESUME',
}

const Vacancy = () => {
  const { vacancyId } = useParams()
  const { goBack } = useHistory()
  const [values, setValues] = useState({ answers: {} })
  const [vacancy, setVacancy] = useState(null)
  const [error, setError] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [open, setOpen] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [modalOpen, setStateModal] = useState(false)
  const videoFile = useRef(null)
  const [stateModalProgressBar, setStateModalProgressBar] = useState(false)
  const { handleUploadFile, progress, loading } = useApplicationUpload()

  const [createApplication, { loading: createLoading }] = useMutation(
    CREATE_APPLICATION,
  )
  const [
    getVacancyData,
    { loading: vacancyLoading, data, refetch },
  ] = useLazyQuery(VACANCY, {
    variables: { _id: vacancyId },
    fetchPolicy: 'network-only',
  })
  const regex = new RegExp(/[&\/\\#,+()$~%.'":*?<>@_{}]/, 'g')

  const validateFields = () => {
    const {
      applierName,
      applierPhone,
      applierEmail,
      applierExpectedSalary,
    } = values

    if (!termsAccepted) {
      setError(
        'Por favor, acepte los términos, condiciones y política de privacidad',
      )
      return false
    }

    if (
      !(applierName && applierPhone && applierEmail && applierExpectedSalary)
    ) {
      setError('Ingrese todos los campos')
      return false
    }

    if (regex.exec(applierName)) {
      setError('Ingrese un nombre válido')
      return false
    }

    if (!isEmail(applierEmail)) {
      setError('Correo electrónico inválido')
      return false
    }

    if (
      applierPhone.length < 10 ||
      applierPhone.length > 15 ||
      isNaN(applierPhone)
    ) {
      setError('Ingrese un número telefónico válido')
      return false
    }

    if (
      applierExpectedSalary < 100 ||
      applierExpectedSalary > 100000000 ||
      isNaN(applierExpectedSalary)
    ) {
      setError('Ingrese un rango de renta válido')
      return false
    }

    if (
      vacancy.applications.find(
        candidate => candidate.applierPhone === `+${applierPhone}`,
      )
    ) {
      setError('Ya se ha registrado un candidato con esta información')
      return false
    }

    if (applierExpectedSalary < 0) {
      setError('La expectativa de renta no puede ser menor a 0')
      return false
    }

    if (
      vacancy.questions.length > 0 &&
      Object.keys(values.answers).length < vacancy.questions.length
    ) {
      setError('Todas las preguntas son obligatorias')
      return false
    }

    if (videoFile.current.files.length)
      if (videoFile.current.files[0].size > 25e6) {
        setError('CV superó los 25 MB máximo del tamaño aceptado')
        return false
      }
    return true
  }

  const createNewApplication = async () => {
    try {
      if (videoFile.current.files.length) setStateModalProgressBar(true)
      const { data } = await createApplication({
        variables: {
          input: {
            ...values,
            applierPhone: `+${values.applierPhone.replace(/[\D]*/g, '')}`,
            vacancy: vacancyId,
            status:
              parseFloat(values.applierExpectedSalary) <= vacancy.maxIncome
                ? 'PENDING_CONTACT'
                : 'TO_UNLINK',
            applierExpectedSalary: parseFloat(values.applierExpectedSalary),
            answers: Object.keys(values.answers).map(el => {
              return {
                question: el,
                answer: values.answers[el],
              }
            }),
            keywords: Object.values(values.answers)
          },
        },
      })
      if (videoFile.current.files.length)
        await handleUploadFile({
          _id: data.createApplication._id,
          file: videoFile.current.files[0],
          type: fileTypes.RESUME,
        })
      else setOpen(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getVacancyData()
    const vacancy = get(data, 'vacancy', {})
    setVacancy(vacancy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!disableBtn) {
      const vacancy = get(data, 'vacancy', {})
      setVacancy(vacancy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (loading) setStateModalProgressBar(true)

    if (!loading && progress === 100) {
      setStateModalProgressBar(false)
      setOpen(true)
      return
    }
  }, [loading, progress, stateModalProgressBar])

  useEffect(() => {
    async function reviewApplication() {
      const validationResult = await validateFields()
      if (validationResult) createNewApplication()
      setDisableBtn(false)
    }
    if (disableBtn) reviewApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy])

  const handleSubmit = async () => {
    setDisableBtn(true)
    const refetchedVacancy = await refetch()
    const { vacancy } = refetchedVacancy.data
    setVacancy({ ...vacancy })
  }

  const handleOpenPrivacyModal = () => {
    setStateModal(true)
  }

  return (
    <>
      <PageHeader
        title={
          vacancyLoading
            ? 'Cargando...'
            : `Tu perfil de postulación: ${vacancy ? vacancy.title : ''}`
        }
      />
      <Grid container direction="column">
        <Container>
        <Typography className={styles.description}>{(vacancy?.description || '')}</Typography>
          <Typography className={styles.label}>
            <b>Con estos datos podremos contactarte y conocer un poco de ti.</b>
          </Typography>
        </Container>

        <Grid container justify="center">
          <Grid item xs={11} sm={8} md={4}>
            <Paper style={{ padding: 30 }}>
              {vacancyLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography style={{ marginBottom: 20 }}>
                    <b>Información</b>
                  </Typography>
                  <Divider className={styles.divider} />
                  <ApplicationForm
                    values={values}
                    setValues={setValues}
                    handleSubmit={handleSubmit}
                    loading={createLoading}
                    questions={vacancy?.questions || []}
                    ref={videoFile}
                  />
                </>
              )}
            </Paper>
            <Grid
              container
              justify="center"
              className={styles.privacyContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                }
                label=""
                className={styles.termAndConditionsCheckbox}
                disabled={vacancyLoading}
              />
              <Typography
                className={styles.privacyLabel}
                onClick={handleOpenPrivacyModal}>
                Autorizo a Jobfitter SpA a recolectar y gestionar mis datos personales
                con el fin de llevar a cabo los procesos de selección. Asimismo, permito
                que dichos datos sean compartidos con nuestros nuestros clientes, cumpliendo
                con la normativa vigente y según lo detallado en la Política de Privacidad.
                Además, acepto y confirmo haber leído los Términos y Condiciones establecidos
                por Jobfitter SpA para el uso de sus plataformas y sitios web, entendiendo la
                descripción de uso, recolección y disposición de Datos Personales detallada en ellos.
              </Typography>
            </Grid>
            <Container className={styles.options}>
              <Button className={styles.backBtn} onClick={() => goBack()}>
                Volver
              </Button>
              <Button
                className={styles.confirmBtn}
                onClick={handleSubmit}
                disabled={disableBtn || loading}>
                Aplicar
              </Button>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <ModalMessage
        open={open}
        title="Postulación exitosa"
        message={
          <Typography>
            ¡Felicidades! Ya estás postulando al cargo de{' '}
            <b>{vacancy ? vacancy.title : ''}</b>. <br />
            <br />
            Gracias por postular a través de nuestra plataforma. Te mantendremos
            informado sobre tu avance en el proceso.{' '}
          </Typography>
        }
        showCancel={false}
        handleConfirm={goBack}
      />
      <Snackbar
        open={!!error}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <Modal
        open={stateModalProgressBar}
        className={styles.modal}
        onClose={() => setStateModalProgressBar(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick>
        <div className={styles.paper}>
          <p>Por favor espere a que se termine de subir su archivo</p>
          <LinearWithValueLabel percentage={progress}></LinearWithValueLabel>
        </div>
      </Modal>
      <PrivacyModal open={modalOpen} setOpen={setStateModal} />
    </>
  )
}

export default Vacancy
