import React from 'react'
import { Button, Divider, Modal, Paper, Typography } from '@material-ui/core'

import styles from './styles.module.css'

const ModalMessage = ({
  cancelText = 'Cancelar',
  handleClose,
  handleConfirm,
  message = 'message not defined',
  okText = 'Confirmar',
  open,
  showCancel = true,
  title = 'title not defined',
  loadingState = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description">
      <Paper className={styles.paper}>
        <Typography className={styles.modalTitle}>{title}</Typography>
        <Typography className={styles.modalMessage}>{message}</Typography>
        <Divider />
        <div className={styles.footer}>
          {showCancel && (
            <Button
              className={styles.cancelBtn}
              onClick={handleClose}
              disabled={loadingState}>
              {cancelText}
            </Button>
          )}
          <Button
            className={styles.confirmBtn}
            onClick={handleConfirm}
            disabled={loadingState}>
            {okText}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default ModalMessage
