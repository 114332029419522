import React, { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import Loading from '../../../../../../components/Loading'
import MessageModal from '../../../../../../components/MessageModal'
import ProtectedComponent from '../../../../../../components/ProtectedComponent'
import { SAURON_ROLE } from '../../../../../../userRoles'

import ApplicantsList from './components/ApplicantsList'
import Options from './components/Options'
import useEmail from './hooks/useEmails'
import { statusOptions } from './data'

import styles from './styles.module.css'

const UPDATE_APPLICATIONS = gql`
  mutation UpdateApplications($ids: [ID!], $input: UpdateApplicationsInput!) {
    updateApplications(ids: $ids, input: $input) {
      _id
    }
  }
`

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($_id: ID!, $input: UpdateApplicationInput!) {
    updateApplication(_id: $_id, input: $input) {
      _id
    }
  }
`

const DELETE_APPLICATION = gql`
  mutation DeleteApplication($_id: ID!) {
    deleteApplication(_id: $_id) {
      _id
    }
  }
`

const status = {
  VIDEO_REQUESTED: 'VIDEO_REQUESTED',
  TO_UNLINK: 'TO_UNLINK',
  UNLINKED: 'UNLINKED',
}

const ApplicantsTab = ({
  applications,
  handleReload,
  questions,
  vacancyStatus,
  handleAppReload,
  meta,
  onPageChange,
  onItemsPerPageChange,
  filters,
  setFilters,
  loading,
  setPagination,
}) => {
  const [updateApplications] = useMutation(UPDATE_APPLICATIONS)
  const [deleteApplication] = useMutation(DELETE_APPLICATION)
  const [updateApplication] = useMutation(UPDATE_APPLICATION)
  const { handleNotifyAccepted, handleNotifyRejected } = useEmail()
  const [applicantsSelected, setApplicants] = useState([])
  const [applicantToDelete, setApplicantToDelete] = useState({})
  const [success, setStateSuccess] = useState('')
  const [error, setStateError] = useState('')
  const [isAllAplicantsSelected, setAllAplicants] = useState(false)
  const [isModalOpen, setStateModal] = useState(false)
  const [isDeleteModalOpen, setStateDeleteModal] = useState(false)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

  const handleStop = event => {
    event.stopPropagation()
  }
  const handleSelectApplicant = applicantId => {
    const applicantsToUnlink = [...applicantsSelected]

    if (applicantsSelected.indexOf(applicantId) !== -1)
      setApplicants(applicantsToUnlink.filter(id => applicantId !== id))
    else {
      applicantsToUnlink.push(applicantId)
      setApplicants(applicantsToUnlink)
    }
  }

  const handleSelectAllAplicants = () => {
    const applicantsToUnlink = applications.filter(
      application => application.status === status.TO_UNLINK,
    )
    if (!isAllAplicantsSelected && applicantsToUnlink.length > 0) {
      setAllAplicants(true)
      setApplicants(applicantsToUnlink.map(applicant => applicant._id))
    } else {
      setAllAplicants(false)
      setApplicants([])
    }
  }

  const handleSendNotification = async (id, nextStatus) => {
    let sendNotification = () => {}

    if (nextStatus === status.VIDEO_REQUESTED)
      sendNotification = handleNotifyAccepted

    if (nextStatus === status.UNLINKED) sendNotification = handleNotifyRejected

    await sendNotification(id)

    setStateSuccess(
      'El postulante acaba de ser notificado con un correo electrónico',
    )
  }
  const handleConfirmAll = async () => {
    try {
      setIsLoadingUpdate(true)
      await updateApplications({
        variables: {
          ids: applicantsSelected,
          input: { status: status.UNLINKED },
        },
      })

      Promise.all(
        applicantsSelected.map(application =>
          handleSendNotification(application, status.UNLINKED),
        ),
      )

      if (applicantsSelected.length === applications.length && meta.page > 1) {
        const page = Math.max(1, meta.page - 1)
        onPageChange(page)
        onItemsPerPageChange(meta.take)
      } else {
        handleReload()
        handleAppReload()
      }
      setStateSuccess('Los postulantes han sido desvinculados correctamente')
      setAllAplicants(false)
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    } finally {
      setAllAplicants(false)
      setStateModal(false)
      setIsLoadingUpdate(false)
    }
  }

  const handleUpdateApplication = async (id, data) => {
    try {
      await updateApplication({ variables: { _id: id, input: { ...data } } })
      await handleReload()
      await handleAppReload()
      setStateSuccess('La acción se ha realizado correctamente.')

      if (
        data.status === status.VIDEO_REQUESTED ||
        data.status === status.UNLINKED
      )
        await handleSendNotification(id, data.status)
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    }
  }

  const handleApplicationToDelete = application => {
    setApplicantToDelete(application)
    setStateDeleteModal(true)
  }

  const handleDeleteApplication = async () => {
    try {
      await deleteApplication({
        variables: {
          _id: applicantToDelete._id,
        },
      })
      setStateDeleteModal(false)
      await handleReload()
      await handleAppReload()
      setStateSuccess('Se ha eliminado la postulación correctamente')
    } catch (error) {
      setStateError('Ha ocurrido un error, por favor intente de nuevo')
      console.log(error)
    }
  }

  const handleFilterChange = useCallback(
    (name, value) => {
      setFilters(prev => ({
        ...prev,
        [name]: value,
      }))
      setPagination(prev => ({ ...prev, page: 1 }))
    },
    [setFilters, setPagination],
  )

  return (
    <>
      <Options
        filters={filters}
        handleFilterChange={handleFilterChange}
        statusOptions={statusOptions}
        handleUnlink={() => setStateModal(true)}
        unlinkButtonDisabled={applicantsSelected.length === 0}
        isUnlinkBtnVisible={filters.applicationStatus === 'TO_UNLINK'}
        take={meta.take}
        onItemsPerPageChange={onItemsPerPageChange}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <ApplicantsList
            applicantions={applications}
            handleStop={handleStop}
            statusOptions={statusOptions}
            handleSelectApplicant={handleSelectApplicant}
            handleSelectAllAplicants={handleSelectAllAplicants}
            applicantsSelected={applicantsSelected}
            handleUpdateApplication={handleUpdateApplication}
            isUnlinkBtnVisible={filters.applicationStatus === 'TO_UNLINK'}
            currentFilterType={filters.applicationStatus}
            questions={questions}
            vacancyStatus={vacancyStatus}
            handleToDelete={handleApplicationToDelete}
            meta={meta}
            onPageChange={onPageChange}
            onItemsPerPageChange={onItemsPerPageChange}
            ludwikaOrder={filters.ludwikaOrderField}
          />
          <MessageModal
            open={isModalOpen}
            okText="Desvincular"
            handleClose={() => setStateModal(false)}
            handleConfirm={handleConfirmAll}
            title={`Alerta: Desvincular ${
              applications.filter(
                application => application.status === status.TO_UNLINK,
              ).length > 1 && isAllAplicantsSelected
                ? 'Seleccionados'
                : applications.find(
                    application => application._id === applicantsSelected[0],
                  )?.applierName
            }`}
            message={
              <div className={styles.statusChange}>
                <Typography className={styles.statusText}>
                  Estado actual: <b>Por desvincular</b>
                </Typography>
                <Typography className={styles.statusText}>
                  Nuevo estado: <b>Desvinculado</b>
                </Typography>
              </div>
            }
            loadingState={isLoadingUpdate}
          />
          <ProtectedComponent allowedRoles={[SAURON_ROLE]}>
            <MessageModal
              open={isDeleteModalOpen}
              okText="Sí"
              cancelText="No"
              handleClose={() => setStateDeleteModal(false)}
              handleConfirm={handleDeleteApplication}
              title="Alerta: Borrar postulante"
              message={
                <Typography>
                  ¿Está seguro que quiere <b>eliminar de manera definitiva</b> a
                  este postulante? Esta acción no podrá revertirse.
                </Typography>
              }
            />
          </ProtectedComponent>
          <Snackbar
            open={!!success}
            onClose={() => setStateSuccess('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success">{success}</Alert>
          </Snackbar>
          <Snackbar
            open={!!error}
            onClose={() => setStateSuccess('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        </>
      )}
    </>
  )
}

ApplicantsTab.defaultProps = {
  applications: [],
  questions: [],
  vacancyStatus: '',
}

export default ApplicantsTab
