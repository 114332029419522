/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

import OldPagination from '../../../../../../components/OldPagination'
import { getPageItems } from '../../../../../../helpers/paginated'
import RankingCard from '../RankingCard'

import styles from './styles.module.css'

// const LudwikaOptions = [
//   {
//     value: 'agreeableness',
//     label: 'Afabilidad',
//   },
//   {
//     value: 'openness',
//     label: 'Apertura',
//   },
//   {
//     value: 'conscientiousness',
//     label: 'Prolijidad',
//   },
//   {
//     value: 'extraversion',
//     label: 'Extroversión',
//   },
//   {
//     value: 'neuroticism',
//     label: 'Estabilidad',
//   },
// ]

const RankingForm = ({ data }) => {
  const [elements, setElements] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [sortField, setSortField] = useState('')

  const setPage = useCallback(
    (page = 1) => {
      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(data, page, 6)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [data],
  )

  useEffect(() => {
    setPage()
  }, [data, setPage])

  // const sortItems = field => {
  //   const ludwikaItems = elements.items.filter(item => item.ludwika)
  //   const noLudwikaItems = elements.items.filter(item => !item.ludwika)
  //   const sortedLudwikaItems = ludwikaItems.sort((a, b) => {
  //     if (a.ludwika[field].value < b.ludwika[field].value) return 1
  //     if (a.ludwika[field].value > b.ludwika[field].value) return -1
  //     return 0
  //   })
  //   const sortedItems = [...sortedLudwikaItems, ...noLudwikaItems]
  //   setElements(prevState => ({
  //     ...prevState,
  //     items: sortedItems,
  //   }))
  // }

  // const handleSort = field => {
  //   setSortField(field)
  //   sortItems(field)
  // }

  const renderPagination = () => (
    <div className={styles.pagination}>
      <OldPagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      <Grid container>
        <Grid item className={styles.cardContainer}>
          {/* <Grid container spacing={2} className={styles.buttonRow}>
            {LudwikaOptions.map(option => (
              <Grid item key={option.value}>
                <Button
                  variant="outlined"
                  onClick={() => handleSort(option.value)}
                  className={
                    sortField === option.value ? styles.activeButton : ''
                  }>
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid> */}

          {elements.exists ? (
            elements.items.map((candidate, index) => (
              <div key={candidate._id}>
                <RankingCard candidate={candidate} position={index + 1} />
              </div>
            ))
          ) : (
            <Typography className={styles.emptyMessage}>
              <b>Aún no existe un ranking con los candidatos presentados</b>
            </Typography>
          )}
        </Grid>
      </Grid>
      {elements.exists && renderPagination()}
    </>
  )
}

RankingForm.defaultProps = {
  data: [],
}

export default RankingForm
