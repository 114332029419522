import React, { useState } from 'react'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import styles from './styles.module.css'

const areas = [
  { label: 'Finanzas', value: 'FINANCE' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Administración', value: 'ADMINISTRATION' },
  { label: 'Logística', value: 'LOGISTICS' },
  { label: 'IT', value: 'IT' },
  { label: 'Construcción', value: 'CONSTRUCTION' },
  { label: 'Hotelería y Restaurantes', value: 'HOTEL' },
  { label: 'RRHH', value: 'HR' },
  { label: 'Operaciones', value: 'OPERATIONS' },
  { label: 'Otro', value: 'OTHER' },
]

const seniorityLevels = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]

/*
const positions = [
  { value: 'GRADUATE', label: 'Recién titulado' },
  { value: 'ANALYST', label: 'Analista' },
  { value: 'ADMINISTRATIVE', label: 'Administrativo' },
  { value: 'BOSS', label: 'Jefe' },
  { value: 'MANAGER', label: 'Gerente' },
]
*/

const handleAddKeywords = params =>
  params
    .split(',')
    .map(el => el.trim())
    .filter(el => el.length > 0)

const Options = ({
  filters,
  setFilters,
  handleSearch,
  companies,
  loading,
  take,
  onItemsPerPageChange,
}) => {
  const renderArea = () => (
    <Grid item xs={5} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que hayan postulado al área seleccionada"
        placement="top">
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="role-select-area-label">Área</InputLabel>
          <Select
            labelId="role-select-area-label"
            id="area-select"
            name="area"
            fullWidth
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: event.target.value,
              })
            }>
            <MenuItem value="ALL">Ver todas</MenuItem>
            {areas.map(area => (
              <MenuItem key={area.value} value={area.value}>
                {area.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSeniorityLevel = () => (
    <Grid item xs={6} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que hayan postulado al cargo selecccionado"
        placement="top">
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="senioritylevel-label">Cargo</InputLabel>
          <Select
            labelId="senioritylevel-label"
            id="senioritylevel-select"
            value={filters.seniorityLevel}
            name="seniorityLevel"
            fullWidth
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: event.target.value,
              })
            }>
            <MenuItem value="ALL">Ver todas</MenuItem>
            {seniorityLevels.map(area => (
              <MenuItem key={area.value} value={area.value}>
                {area.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSalary = () => (
    <Grid item xs={5} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que pidan un monto igual o menor al monto que escribas"
        placement="top">
        <FormControl component="fieldset">
          <TextField
            id="outlined-basic"
            label="Renta máxima"
            variant="outlined"
            name="expectedSalary"
            type="number"
            onKeyDown={event => event.key === 'Enter' && handleSearch()}
            fullWidth
            placeholder="Ej. 1200000"
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: parseFloat(event.target.value),
              })
            }
          />
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const [show, setShow] = useState(false)

  const renderSearchByCompany = () => (
    <Grid item xs={5} sm={2}>
      <Tooltip
        title="Te mostrará candidatos que hayan postulado a la empresa seleccionada"
        placement="top"
        open={show}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="company-select-label">Empresa</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            name="companyId"
            fullWidth
            onClick={() => setShow(false)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: event.target.value,
              })
            }>
            <MenuItem
              value="ALL"
              onClick={() => setShow(false)}
              onMouseEnter={() => setShow(false)}
              onMouseLeave={() => setShow(false)}>
              Ver todas
            </MenuItem>
            {companies.map(company => (
              <MenuItem
                key={company._id}
                value={company._id}
                onClick={() => setShow(false)}
                onMouseEnter={() => setShow(false)}
                onMouseLeave={() => setShow(false)}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSearch = () => (
    <Grid item xs={5} sm={4}>
      <Tooltip
        title="Te mostrará candidatos que tengan en su nombre o correo las palabras que escribas, o bien en el nombre de una vacante a la que postularon"
        placement="top">
        <FormControl component="fieldset" className={styles.search}>
          <TextField
            id="outlined-basic"
            label="Búsqueda por correo o nombre de candidato o vacante"
            variant="outlined"
            name="name"
            onKeyDown={event => event.key === 'Enter' && handleSearch()}
            fullWidth
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: event.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderSearchByKeywords = () => (
    <Grid item xs={5} sm={4}>
      <FormControl component="fieldset" className={styles.search}>
        <Tooltip
          title="Puedes separar las palabras claves con comas"
          placement="top">
          <TextField
            id="outlined-basic"
            label="Búsqueda por palabras claves"
            variant="outlined"
            name="keywords"
            fullWidth
            onChange={event =>
              setFilters({
                ...filters,
                [event.target.name]: handleAddKeywords(event.target.value),
              })
            }
            onKeyDown={event => event.key === 'Enter' && handleSearch()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </FormControl>
    </Grid>
  )

  const renderItems = () => (
    <Grid item xs={5} sm={1}>
      <Tooltip
        title="Te mostrará la cantidad seleccionada de candidatos por página"
        placement="top">
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="items-select-label">Cantidad</InputLabel>
          <Select
            labelId="items-select-label"
            id="items-select"
            value={take}
            defaultValue={5}
            name="maxItems"
            fullWidth
            onChange={e => onItemsPerPageChange(Number(e.target.value))}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </Grid>
  )

  const renderButton = () => (
    <Grid item xs={5} sm={2}>
      <div className={styles.button}>
        <Button
          className={styles.searchButton}
          disabled={loading}
          onClick={handleSearch}>
          Buscar
        </Button>
      </div>
    </Grid>
  )

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.searchFilters}>
        {renderSearch()}
        {renderSearchByKeywords()}
        {renderSalary()}
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.searchFilters}>
        {renderSearchByCompany()}
        {renderArea()}
        {renderSeniorityLevel()}
        {renderItems()}
        {renderButton()}
      </Grid>
      <Divider style={{ marginTop: 25, marginBottom: 30 }} />
    </>
  )
}

export default Options
