import React from 'react'
import ReactPlayer from 'react-player'
import {
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'

import styles from './styles.module.css'

const CDN_URL = process.env.REACT_APP_USERS_STORAGE_CDN
const PLAYER_CONFIG = {
  file: {
    attributes: {
      controlslist: 'nodownload',
    },
  },
}

const RankingCard = ({ candidate, position }) => {
  return (
    <Grid className={styles.mainContentCard}>
      <Paper elevation={3} square className={styles.boxItems}>
        <div className={styles.textName}>
          <Typography className={styles.text} variant="h4" component="h3">
            #{position}
          </Typography>
          <Typography className={styles.text} variant="h4" component="h3">
            {candidate.applierName}
          </Typography>
        </div>
        <div className={styles.videoPlayer}>
          <ReactPlayer
            url={`${CDN_URL}/${candidate.video}`}
            config={PLAYER_CONFIG}
            height="400px"
            width="100%"
            controls
          />
          <div className={styles.divDetail}>
            <FormControl component="fieldset" className={styles.inputGroup}>
              <TextField
                disabled
                name="maximun_rent"
                label="Número de Teléfono"
                value={candidate.applierPhone}
                variant="outlined"
              />
            </FormControl>
            <FormControl component="fieldset" className={styles.inputGroup}>
              <TextField
                disabled
                name="maximun_rent"
                label="Expectativa de Renta"
                value={candidate.applierExpectedSalary}
              />
            </FormControl>
            {candidate.resume ? (
              <a
                href={`${CDN_URL}/${candidate.resume}`}
                className={styles.downloadLink}
                target="_blank"
                download>
                <Button variant="contained" className={styles.downloadBtn}>
                  Descarga de CV
                </Button>
              </a>
            ) : null}
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

export default RankingCard
