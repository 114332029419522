import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { FormControl, MenuItem, Select } from '@material-ui/core'

import styles from './styles.module.css'

const offset = 3

const Pagination = ({ meta, onPageChange, onItemsPerPageChange }) => {
  const {
    page: currentPage,
    totalPages,
    totalData,
    hasNextPage,
    hasPrevPage: hasPreviousPage,
    take,
  } = meta ?? {}

  const renderPages = pages => {
    return pages.map((pageNum, index) => {
      return (
        <div
          className={styles.uniqPage}
          key={index.toString()}
          onClick={() => onPageChange(pageNum)}>
          <div className={styles.page}>{pageNum}</div>
        </div>
      )
    })
  }

  const renderCurrentPage = () => {
    return (
      <div className={styles.currentPage}>
        <strong>{currentPage}</strong>
      </div>
    )
  }

  const renderPreviousPages = () => {
    const pages = []
    for (let i = currentPage - offset; i < currentPage; i++)
      if (i > 0) pages.push(i)

    return renderPages(pages)
  }

  const renderNextPages = () => {
    const pages = []
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + offset, totalPages);
      i++
    )
      pages.push(i)

    return renderPages(pages)
  }

  const renderLeft = () => {
    return (
      <div
        className={hasPreviousPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasPreviousPage && onPageChange(currentPage - 1)}>
        <MdKeyboardArrowLeft size={25} />
      </div>
    )
  }

  const renderFirstPage = () => {
    return (
      <div
        className={hasPreviousPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasPreviousPage && onPageChange(1)}>
        <div className={styles.contentDoubleArrow}>
          <MdKeyboardArrowLeft size={25} />
          <div style={{ marginInline: -10 }} />
          <MdKeyboardArrowLeft size={25} />
          <div style={{ marginRight: -5 }} />
        </div>
      </div>
    )
  }

  const renderLastPage = () => {
    return (
      <div
        className={hasNextPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasNextPage && onPageChange(totalPages)}>
        <div className={styles.contentDoubleArrow}>
          <MdKeyboardArrowRight size={25} />
          <div style={{ marginInline: -10 }} />
          <MdKeyboardArrowRight size={25} />
          <div style={{ marginLeft: -5 }} />
        </div>
      </div>
    )
  }

  const renderRight = () => {
    return (
      <div
        className={hasNextPage ? styles.iconEnabled : styles.iconDisabled}
        onClick={() => hasNextPage && onPageChange(currentPage + 1)}>
        <MdKeyboardArrowRight size={25} />
      </div>
    )
  }

  const renderDataInfo = () => {
    const start = (currentPage - 1) * take + 1
    const end = Math.min(currentPage * take, totalData)
    return (
      <div className={styles.dataInfo}>
        Mostrando {start}-{end} de {totalData}
      </div>
    )
  }

  const pagination = () => {
    return (
      <div className={styles.pagination}>
        <div className={styles.content}>
          <div className={styles.arrow}>{renderFirstPage()}</div>
          <div className={styles.arrow}>{renderLeft()}</div>
          <div className={styles.leftPages}>{renderPreviousPages()}</div>
          <div className={styles.centerPage}>{renderCurrentPage()}</div>
          <div className={styles.rightPages}>{renderNextPages()}</div>
          <div className={styles.arrow}>{renderRight()}</div>
          <div className={styles.arrow}>{renderLastPage()}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.paginationContainer}>
      <div className="flex flex-column gap-2 items-center">
        {pagination()}
        {renderDataInfo()}
      </div>

      <FormControl style={{ width: '100%' }}>
        <Select
          labelId="items-select-label"
          id="items-select"
          value={take}
          defaultValue={5}
          name="maxItems"
          fullWidth
          onChange={e => onItemsPerPageChange(Number(e.target.value))}>
          <MenuItem value={5}>5 por página</MenuItem>
          <MenuItem value={10}>10 por página</MenuItem>
          <MenuItem value={15}>15 por página</MenuItem>
          <MenuItem value={25}>25 por página</MenuItem>
          <MenuItem value={50}>50 por página</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default Pagination
