import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'

import OldPagination from '../../../../../../../components/OldPagination'
import { getPageItems } from '../../../../../../../helpers/paginated'

import CompanyMenu from './components/CompanyMenu'

import styles from './styles.module.css'

const CompanyList = ({
  companies,
  search,
  type,
  handleEdit,
  handleDelete,
  maxItems,
}) => {
  const [elements, setElements] = useState({})

  const setPage = useCallback(
    (page = 1) => {
      const companiesFiltered = companies.filter(
        val =>
          val.name.toLowerCase().includes(search.toLowerCase()) &&
          (type === 'ALL' ? true : type === val.plan),
      )

      const {
        items,
        totalPages,
        hasNextPage,
        hasPreviousPage,
        exists,
      } = getPageItems(companiesFiltered, page, maxItems)
      setElements({
        exists,
        items,
        currentPage: page,
        totalPages,
        hasNextPage,
        hasPreviousPage,
      })
    },
    [companies, maxItems, search, type],
  )

  useEffect(() => {
    setPage()
  }, [search, companies, type, maxItems, setPage])

  const renderPagination = () => (
    <div className={styles.pagination}>
      <OldPagination
        currentPage={elements.currentPage}
        totalPages={elements.totalPages}
        search={setPage}
        hasNextPage={elements.hasNextPage}
        hasPreviousPage={elements.hasPreviousPage}
      />
    </div>
  )

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={3}
        className={styles.mainContentList}>
        {elements.exists ? (
          elements.items.map(company => (
            <Grid key={company._id} item xs>
              <Paper variant="elevation" square className={styles.paperList}>
                <Grid className={styles.infoVacancy}>
                  <Typography component={'span'} className={styles.vacTitle}>
                    <b>{company.name}</b>
                  </Typography>
                </Grid>
                <Grid className={styles.options}>
                  <CompanyMenu
                    onEdit={() => handleEdit(company)}
                    onDelete={() => handleDelete(company)}
                  />
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Typography className={styles.notFoundText}>
            No se encontraron resultados
          </Typography>
        )}
      </Grid>
      {elements.exists && renderPagination()}
    </>
  )
}

export default CompanyList
