import React, { useContext } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import Pagination from '../../../../../../../../components/Pagination'
import { Context } from '../../../../../../../../context'

import ApplicantMenu from './components/ApplicantMenu'
import ApplicantStatusSelect from './components/ApplicantStatusSelect'
import ApplicationInfo from './components/ApplicationInfo'
import CommentsSection from './components/ApplicationInfo/components/CommentsSection'

import styles from './styles.module.css'

const ApplicantsList = ({
  applicantions,
  handleStop,
  statusOptions,
  handleSelectApplicant,
  applicantsSelected,
  handleSelectAllAplicants,
  handleUpdateApplication,
  isUnlinkBtnVisible,
  currentFilterType,
  questions,
  vacancyStatus,
  handleToDelete,
  meta,
  onPageChange,
  onItemsPerPageChange,
  ludwikaOrder,
}) => {
  const authInfo = useContext(Context)

  const exists = applicantions.length > 0

  return (
    <>
      {isUnlinkBtnVisible ? (
        <FormControlLabel
          className={styles.selectAllCheckbox}
          style={{ marginLeft: '1rem', marginTop: '2rem' }}
          control={<Checkbox onChange={handleSelectAllAplicants} />}
          label="Seleccionar todos"
        />
      ) : null}
      {exists ? (
        applicantions.map(el => (
          <Accordion key={el._id} className={styles.applicationListItem}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`${el._id}-content`}
              id={`${el._id}-header`}>
              <div className={styles.containerText}>
                <Typography className={styles.textName}>
                  {el.status === 'TO_UNLINK' &&
                  currentFilterType === 'TO_UNLINK' ? (
                    <Checkbox
                      onChange={() => handleSelectApplicant(el._id)}
                      checked={applicantsSelected.indexOf(el._id) !== -1}
                    />
                  ) : null}
                  <b>{el.applierName}</b> - {el.createdAt}
                </Typography>
                {/* {ludwikaOrder &&
                  el.ludwika?.[ludwikaOrder].value !== undefined && (
                    <Typography className={styles.textLudwika}>
                      {el.status === 'TO_UNLINK' &&
                      currentFilterType === 'TO_UNLINK' ? (
                        <Checkbox
                          onChange={() => handleSelectApplicant(el._id)}
                          checked={applicantsSelected.indexOf(el._id) !== -1}
                        />
                      ) : null}
                      <b>Puntaje Ludwika</b>: {el.ludwika?.[ludwikaOrder].value}
                    </Typography>
                  )} */}

                <ApplicantStatusSelect
                  state={el.status}
                  handleStop={handleStop}
                  statusOptions={statusOptions}
                  handleStatusChange={status =>
                    handleUpdateApplication(el._id, { status })
                  }
                  applicantName={el.applierName}
                  vacancyStatus={vacancyStatus}
                />
              </div>
              <div>
                <ApplicantMenu handleToDelete={() => handleToDelete(el)} />
              </div>
            </AccordionSummary>
            <AccordionDetails className={styles.applicantInfo}>
              <ApplicationInfo
                application={el}
                user={authInfo.user}
                handleInterviewsComments={handleUpdateApplication}
                questions={questions}
              />
            </AccordionDetails>
            <Grid className={styles.addNote}>
              <CommentsSection
                buttonLabel="Agregar nota"
                user={authInfo.user}
                comments={el.notes}
                handleAddComment={commentsUpdated => {
                  handleUpdateApplication(el._id, {
                    notes: [...commentsUpdated],
                  })
                }}
              />
            </Grid>
          </Accordion>
        ))
      ) : (
        <Typography className={styles.notFoundText}>
          No se encontraron resultados
        </Typography>
      )}
      {exists && (
        <div className={styles.pagination}>
          <Pagination
            meta={meta}
            onPageChange={onPageChange}
            onItemsPerPageChange={onItemsPerPageChange}
          />
        </div>
      )}
    </>
  )
}

ApplicantsList.defaultProps = {
  applicantions: [],
}

export default ApplicantsList
