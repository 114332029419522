import React from 'react'
import { Typography } from '@material-ui/core'

import styles from './styles.module.css'

const TermsAndConditions = () => (
  <div>
    <Typography className={styles.centerText}>
      <b>
        <u>TÉRMINOS LEGALES Y CONDICIONES GENERALES DE USO</u>
      </b>
    </Typography>
    <Typography className={styles.centerText}>
      <b>
        <u>JOBFITTER</u>
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
    El sitio web www.jobfitter.cl y sus softwares  (en adelante,  “las Plataformas”) son de titularidad de Jobfitter SpA, (en adelante “Jobfitter”), sociedad debidamente constituida en Chile, con RUT 77.270.771-1, con domicilio para estos efectos en Don Carlos 2939, oficina 208, Las Condes representada legalmente por José Alcalde Fuenzalida.
    </Typography>
    <Typography className={styles.paragraph}>
    Por medio de los presentes Términos y Condiciones Generales de Uso (En adelante “T&C”), se informa a quienes postulen en las Plataformas (En adelante el “postulante” o los “postulantes”), las condiciones que rigen el acceso y uso de estas, y todo tipo de información, contenido, imagen, video u otra materia que ellas contengan, así como de las condiciones que rigen la relación que aceptar estos T&C se genera entre Jobfitter y el Postulante.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>PRIMERO</u>: Sobre Jobfitter.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
    Jobfitter es una empresa que conecta personas que se encuentran en búsqueda de trabajo con diversas empresas que cuenten con cargos vacantes o postulaciones abiertas, con la finalidad de formar equipos, para lo cual, Jobfitter recomendará a las empresas referidas, candidatos que puedan tener una mayor probabilidad de calce con el cargo vacante.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SEGUNDO</u>: Aceptación de los Términos De Uso.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
    El acceso a las Plataformas es gratuito para los postulantes, y no supone ningún tipo de relación de carácter comercial entre Jobfitter y el Postulante.
    </Typography>
    <Typography className={styles.paragraph}>
    Mediante la utilización de Jobfitter, en cuanto a los servicios indicados en el artículo TERCERO de este documento, el Postulante acepta todos los Términos y Condiciones aquí contenidos y entiende que estos le son legalmente vinculantes y obligatorios. Asimismo, se entiende que acepta todas las demás reglas de operación, políticas y procedimientos que se encuentran publicados en las diversas Plataformas.
    </Typography>
    <Typography className={styles.paragraph}>
    Sin perjuicio de lo anterior, algunos servicios accesorios de Jobfitter pueden estar sujetos a términos y condiciones adicionales. En ese caso, el uso que el Postulante haga de dichos servicios estará sujeto, además, a los referidos términos y condiciones adicionales, que se incorporan a los presentes Términos y Condiciones por medio de esta referencia.
    </Typography>
    <Typography className={styles.paragraph}>
    Jobfitter se reserva el derecho a modificar los presentes Términos y Condiciones. Las referidas modificaciones serán debidamente publicadas en las Plataformas de Jobfitter y notificadas al Postulante a través del software, página web o email registrado por el Postulante. Dichos cambios, sean en forma de modificaciones, adiciones o eliminaciones, serán efectivos en la fecha que se especifique en el aviso o notificación. El uso continuo de Jobfitter por el Postulante, luego de la publicación y notificación de cualquier cambio en estos Términos de servicio significará que el Postulante ha aceptado dichas modificaciones.
    </Typography>
    <Typography className={styles.paragraph}>
    En caso de que el Postulante no esté de acuerdo con alguna de las disposiciones contenidas en lso presentes T&C, no podrá hacer uso de las Plataformas.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>TERCERO</u>: SERVICIOS.
      </b>
    </Typography>
    <ol>
      <li className={styles.paragraph}>
        <Typography className={styles.paragraph}>
          <b>Servicio de Recomendación de Candidatos.</b>
        </Typography>
        <Typography className={styles.paragraph}>
          Jobfitter recomienda, a través de su plataforma, candidatos para un determinado cargo a empresas que se encuentren en búsqueda de personal (en adelante, “Empresas”).
        </Typography>
        <Typography className={styles.paragraph}>
          Para estos efectos de lo anterior, los Postulantes deberán seguir los siguientes pasos:
        </Typography>
        <ol type="a">
          <li className={styles.paragraph}>
            <Typography className={styles.paragraph}>
              <b>Postulación.</b>
            </Typography>
            <Typography className={styles.paragraph}>
              La postulación de los Postulantes se realiza a través de la página web www.jobfitter.cl, respondiendo lo siguiente:
            </Typography>
          </li>
          <ol>
            <li>
              <Typography>
                RUT
              </Typography>
            </li>
            <li>
              <Typography>
                Nombre
              </Typography>
            </li>
            <li>
              <Typography>
                Correo electrónico
              </Typography>
            </li>
            <li>
              <Typography>
                Teléfono
              </Typography>
            </li>
            <li>
              <Typography>
              Expectativas de renta
              </Typography>
            </li>
            <li>
              <Typography className={styles.paragraph}>
              Entre otros afines al cargo
              </Typography>
            </li>
          </ol>
          <li className={styles.paragraph}>
            <Typography className={styles.paragraph}>
              <b>Antecedentes laborales.</b>
            </Typography>
            <Typography className={styles.paragraph}>
              Opcionalmente los Postulantes podrán subir tu currículum vitae (CV). Así, el CV será revisado por Jobfitter para determinar la idoneidad respecto al cargo al que el Postulante postula, o cualquier otro cargo que pueda calzar con su experiencia. El CV que se suba podría ser enviado a las Empresas que busquen trabajadores afines a tus aptitudes y requerimientos laborales.
            </Typography>
            <Typography className={styles.paragraph}>
              El Postulante será responsable de mantener su información de contacto y currículum vitae actualizados e informar a Jobfitter en caso de que la información proporcionada contenga errores, con la finalidad de que éstos sean subsanados a la mayor brevedad posible.
            </Typography>
          </li>
          <li className={styles.paragraph}>
            <Typography className={styles.paragraph}>
              <b>Video Entrevistas.</b>
            </Typography>
            <Typography className={styles.paragraph}>
            Si Jobfitter considera que la postulación de un Postulante y CV cumplen con lo requerido por las Empresas para un cargo determinado, se le solicitará al Postulante una video entrevista de presentación personal en el que deberá realizar una entrevista virtual en su celular respondiendo preguntas estandarizadas que suelen realizarse en entrevistas de trabajo (En adelante “Video Entrevista”). Ello con la finalidad de que la Empresa pueda conocer el perfil del Postulante más allá de lo que se expresa en tu CV.
            </Typography>
            <Typography className={styles.paragraph}>
            Las preguntas para realizar el video, se solicitarán por correo electrónico, al mail registrado por los Postulantes. Una vez que el Postulante tenga listo su video, deberá subirlo a la plataforma en el link enviado al mismo correo. El video que se suba será mostrado a las Empresas afines a los requerimientos del Postulante y utilizado por Jobfitter para obtener información indispensable para mejorar nuestros servicios y Plataformas. Jobfitter se obliga a no divulgar la entrevista en ninguna de sus Plataformas o a terceros distintos a las Empresas, sin la autorización previa y por escrito del Postulante.
            </Typography>
            <Typography className={styles.paragraph}>
            La información del Postulante será incorporada automáticamente a las Plataformas Jobfitter. En ella se incluyen algoritmos que facilitan o ayudan a definir el grado de idoneidad entre dicha información proporcionada por el candidato y el cargo al que postula.
            </Typography>
          </li>
          <li className={styles.paragraph}>
            <Typography className={styles.paragraph}>
              <b>Software de Informe de Competencias Laborales (Ludwika)</b>
            </Typography>
            <Typography className={styles.paragraph}>
              Adicionalmente al uso de la Video Entrevista por el Postulante por parte de la Empresa, Jobfitter, a través de softwares y algoritmos de su propiedad, genera un perfil de competencias laborales del Postulante y ranking por competencias, el cual es entregado a las Empresas en formato de Informe, con el propósito de ajustar el perfil laboral de los Postulantes a los requerimientos específicos de las Empresas. Si te interesa tener más detalle cómo operan nuestros software y algoritmos, puedes escribirnos a feedback@jobfitter.cl
            </Typography>
          </li>
          <li className={styles.paragraph}>
            <Typography className={styles.paragraph}>
              <b>Información Personal</b>
            </Typography>
            <Typography className={styles.paragraph}>
              La información personal que sea entregada por nuestros Postulantes será debidamente almacenada y custodiada por Jobfitter, y no será divulgada a terceros distintos de las Empresas, y se someterá a los términos de Privacidad de Datos referidos en la cláusula  siguiente.
            </Typography>
            <Typography className={styles.paragraph}>
            Jobfitter se reserva el derecho a ejercer las acciones legales correspondientes contra el Postulante, en caso de que compruebe, o tenga la sospecha, de que la información o antecedentes proporcionados son falsos o no verídicos.
            </Typography>
          </li>
        </ol>
      </li>
    </ol>
    <Typography className={styles.paragraph}>
      <b>
        <u>CUARTO</u>: SEGURIDAD DE LAS PLATAFORMAS Y RESPONSABILIDAD.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter mantiene altos estándares de seguridad para que la navegación y todos los procesos que se realizan en las Plataformas se ejecuten en las mejores condiciones, asegurando tanto la autenticidad de las Plataformas como el cifrado de toda la información y datos personales que se entregan recíprocamente.
    </Typography>
    <Typography className={styles.paragraph}>
      En las Plataformas, el Postulante podrá encontrar vínculos de acceso a otros Sitios Web o contenidos de terceros, los cuales están sujetos a términos y condiciones diferentes a los de Jobfitter.
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter solo es responsable por sus Plataformas y propios contenidos, mas no por la información y contenidos provistos en sitios de terceros, ni de los daños o perjuicios que provengan del uso indevido o inadecuado de las tecnologías por parte del Postulante, o de mala fe o en contravención a los presenter T&C, o que sean consecuencia de virus u otros elementos almacenados en los sistemas electrónicos de los Postulantes.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>QUINTO</u>: VIGENCIA Y MODIFICACIÓN DE LA INFORMACIÓN.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      Estos T&C permanecerán vigentes mientras se encuentren disponibles y publicados para los Postulantes en las Plataformas.
    </Typography>
    <Typography className={styles.paragraph}>
      Jobfitter se reserva la facultad de modificar los T&C, lo que será informado oportunamente al Postulante, quien deberá aceptar los nuevos T&C en la misma modalidad dispuesta precedentemente, si desea seguir haciendo uso de los servicios de Jobfitter.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SEXTO</u>: PROPIEDAD INTELECTUAL.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
      En el ejercicio de los presentes Términos y Condiciones, y en virtud de la relación que se suscite entre Jobfitter y sus Postulantes, se entenderá que todas las marcas , patentes, derechos de autor, secretos comerciales, diseños, obras, programas, aplicaciones computacionales y otros derechos de propiedad intelectual relativos a Jobfitter y sus Plataformas, son de propiedad de Jobfitter SpA (en adelante, la “Sociedad”), o de terceros que han debidamente licenciado a Jobfitter SpA el uso de los mismos (en adelante, la “Propiedad Intelectual”).
    </Typography>
    <Typography className={styles.paragraph}>
      De esta forma, el Postulante no deberá realizar ninguna acción que ponga en riesgo o pudiera poner en riesgo la Propiedad Intelectual de la Sociedad. Asimismo, el Postulante declara que no participará directa o indirectamente en cualquier procedimiento disputando la titularidad o validez de la Propiedad Intelectual de la Sociedad, y que ésta es y será la única y exclusiva titular sobre marcas, patentes, derechos de autor, secretos comerciales, diseños, obras, programas, aplicaciones computacionales, y en general cualquier derecho de propiedad industrial e intelectual, tanto obtenido como pendientes, relacionados con Jobfitter y sus Plataformas, por lo que el Postulante no llevará a cabo actos que tiendan a afectar el valor de las invenciones, mejoras o diseños derivados total o parcialmente de los servicios prestados por la Sociedad.
    </Typography>
    <Typography className={styles.paragraph}>
      El Postulante no podrá realizar actos que atenten contra el correcto funcionamiento de las Plataformas, ni utilizar técnicas de ingeniería inversa, descompilar o desensamblar el software; hacer copias del software; utilizar el software de manera ilegal, o transmitir información ilegal, amenazante, ofensiva, injuriosa, difamatoria, obscena, vulgar, pornográfica, profana o indecente de ningún tipo, ni siquiera imágenes o lenguaje de esta naturaleza por las Plataformas; infringir los derechos de terceros, o transmitir mensajes que constituyan, fomenten o inciten una conducta que constituiría un delito penal u origine responsabilidad civil; transmitir información, documentos u otro material que contenga un virus u otro componente dañino; usar cualquier marca, logotipo, imágenes, textos, diseño de página, formatos u otra información de propiedad exclusiva de la Sociedad, sin su consentimiento escrito y expreso; intentar sondear, escanear o evaluar la vulnerabilidad de las Plataformas, o de cualquier sistema o red asociados, o violar las medidas de seguridad o autenticación sin una autorización adecuada; y reproducir, copiar, traducir, modificar, adaptar, descompilar, desarmar, crear cualquier obra derivada de, traducción de, realce, actualización, adición, desarrollo o mejora de las Plataformas o de la propiedad intelectual de Jobfitter.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>SÉPTIMO</u>: USO DE DATOS.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
    Jobfitter se obliga a resguardar la información entregada y obtenida por los Postulantes, y adoptar los medios y técnicas que estén a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y/o apropiación de estos por terceros, de acuerdo a la Política de Privacidad de Jobfitter, la que se entiende incorporada a los presentes Términos y Condiciones, para todo los efectos legales y contractuales a los que haya lugar.
    </Typography>
    <Typography className={styles.paragraph}>
      <b>
        <u>OCTAVO</u>: DECLARACIONES Y OBLIGACIONES DEL POSTULANTE.
      </b>
    </Typography>
    <ol>
      <li className={styles.paragraph}>
        <Typography>
        El Postulante declara, reconoce y acepta que:
        </Typography>
      </li>
      <Typography className={styles.paragraph}>
        El uso de Jobfitter, en cualquiera de sus Plataformas, se efectúa libre y voluntariamente y bajo su única y exclusiva responsabilidad.
      </Typography>
      <Typography className={styles.paragraph}>
        Jobfitter es solo un sistema de reclutamiento de personal que recomienda candidatos, por lo que todas y cada una de las elecciones que realicen las Empresas serán de exclusiva responsabilidad de las mismas, y Jobfitter no podrá ser responsable, directa ni indirectamente, de las decisiones o elecciones que ellas efectúen.
      </Typography>
      <Typography className={styles.paragraph}>
        Toda la información proporcionada por él es verídica y no contiene antecedentes falsos o que puedan inducir a error o engaño de Jobfitter o de las Empresas.
      </Typography>
      <Typography className={styles.paragraph}>
        Será íntegramente responsable de todo daño o perjuicio ocasionado a Jobfitter o a las Empresas por proporcionar, mantener en su poder y/o difundir información o antecedentes personales o académicos falsos.
      </Typography>
      <li className={styles.paragraph}>
        <Typography>
        El Postulante se obliga a:
        </Typography>
        <ol type="a">
            <li>
              <Typography>
              Usar las Plataformas conforme a los presentes T&C, la buena fe y la legislación vigente;
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de realizar uso de las Plataformas y sus funcionalidades para fines fraudulentos, abusivos;
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de modificar, desactivar, interferir o burlar las protecciones, programas o desarrollos de las Plataformas.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de realizar ingeniería inversa, o desarmar los softwares u otro producto o proceso a los que se acceda a través de las Plataformas.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de introducir, publicar o reproducir de cualquier forma algún material diseñado para interrumpir, destruir o limitar la funcionalidad del software, hardware o equipos asociados con las Plataformas, incluido material que contenga virus o cualquier otro código, archivos o programas maliciosos.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de insertar en páginas webs, plataformas, foros, chats, blogs o redes sociales ajenas cualquier tipo de enlace o vínculo que permita reproducir o ingresar a las Plataformas o los contenidos de ellas.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de introducir y/o difundir en las Plataformas contenidos que atenten contra los derechos humanos y la dignidad de las personas como por ejemplo contenido racista, xenófobo o pornográfico.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de archivar, descargar, distribuir, modificar, transmitir, mostrar, ejecutar, duplicar, publicar, reproducir, crear obras derivadas, otorgar licencias, ofrecer en venta o usar cualquier tipo de información contenida en las Plataformas para un propósito diverso de los autorizados expresamente en los T&C, sin consentimiento previo y por escrito de Jobfitter.
              </Typography>
            </li>
            <li>
              <Typography>
              Abstenerse de copiar, modificar, vender, reproducir, sublicenciar o arrendar a terceros cualquier contenido de Jobfitter.
              </Typography>
            </li>
          </ol>
      </li>
    </ol>
    <Typography className={styles.paragraph}>
      <b>
        <u>NOVENO</u>: LEGISLACIÓN APLICABLE.
      </b>
    </Typography>
    <Typography className={styles.paragraph}>
    Si alguno de los apartados fuera judicialmente declarado inválido, nulo o por cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de los Términos y Condiciones.
    </Typography>
    <Typography className={styles.paragraph}>
    Los Términos y Condiciones que rigen las Plataformas y todas las relaciones que pudieran derivarse de éste, se regirán por la normativa y quedarán sujetos a las leyes de la República de Chile.
    </Typography>
    <Typography className={styles.paragraph}>
    Cualquier controversia que pudiera derivarse de la aplicación, interpretación, duración, validez, o ejecución de estos Términos y Condiciones será sometida al conocimiento de los tribunales ordinarios de justicia competentes. Lo anterior, sin perjuicio del derecho del postulante de acudir siempre ante el tribunal competente conforme a lo dispuesto en el artículo 3 letra g) y demás disposiciones de la Ley de Protección a los Derechos de los Consumidores.
    </Typography>
  </div>
)

export default TermsAndConditions
