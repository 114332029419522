import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import MessageModal from '../../components/MessageModal'
import PageHeader from '../../components/PageHeader'
import PrivacyModal from '../../components/PrivacyModal'
import LinearWithValueLabel from '../../components/ProgressBar/ProgressBarWithLaber'
import SpontaneousForm from '../../components/SpontaneousForm'
import useSpontaneousUpload from '../../hooks/useSpontaneousUpload'

import { filterKeywords, validateFields } from './validation'

import styles from './styles.module.css'

const CREATE_SPONTANEOUS = gql`
  mutation CreateSpontaneous($input: CreateSpontaneousInput!) {
    createSpontaneous(input: $input) {
      _id
    }
  }
`

const Upload = () => {
  const { push } = useHistory()
  const [values, setValues] = useState({ areas: [] })
  const [error, setError] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [open, setOpen] = useState(false)
  const [modalOpen, setStateModal] = useState(false)
  const [createSpontaneous] = useMutation(CREATE_SPONTANEOUS)
  const videoFile = useRef(null)
  const { handleUploadFile, loading, progress } = useSpontaneousUpload()
  const [stateModalProgressBar, setStateModalProgressBar] = useState(false)

  useEffect(() => {
    if (loading) setStateModalProgressBar(true)

    if (!loading && progress === 100) {
      setStateModalProgressBar(false)
      setOpen(true)
      return
    }
  }, [loading, progress, stateModalProgressBar])

  const handleSubmit = async () => {
    if (!validateFields(values, videoFile, termsAccepted, setError)) return
    setDisableBtn(true)
    try {
      setStateModalProgressBar(true)
      const { data } = await createSpontaneous({
        variables: {
          input: {
            ...values,
            keywords: filterKeywords(values.keywords),
            phone: `+${values.phone.replace(/[\D]*/g, '')}`,
            expectedSalary: parseFloat(values.expectedSalary),
          },
        },
      })
      await handleUploadFile({
        _id: data.createSpontaneous._id,
        file: videoFile.current.files[0],
      })
    } catch (error) {
      console.error(error)
    }
    setDisableBtn(false)
  }

  const handleReturn = () => {
    push('/')
  }

  const handleToggleTerms = () => {
    setTermsAccepted(!termsAccepted)
  }

  const handleClearError = () => {
    setError('')
  }

  const handleOpenPrivacyModal = () => {
    setStateModal(true)
  }

  return (
    <>
      <PageHeader title="¿En búsqueda de trabajo?" type="spontaneous" />
      <Container>
        <Typography className={styles.message}>
          Déjanos tus datos y CV para conocer tu perfil y así poder considerarte
          en futuras búsquedas
        </Typography>
      </Container>
      <SpontaneousForm values={values} setValues={setValues} ref={videoFile} />
      <Grid container justify="center">
        <Grid item xs={11} sm={8} md={4}>
          <Grid justify="center" className={styles.privacyContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={handleToggleTerms}
                />
              }
              label=""
              className={styles.termAndConditionsCheckbox}
            />
            <Typography
              className={styles.privacyLabel}
              onClick={handleOpenPrivacyModal}>
                Autorizo a Jobfitter SpA a recolectar y gestionar mis datos personales
                con el fin de llevar a cabo los procesos de selección. Asimismo, permito
                que dichos datos sean compartidos con nuestros nuestros clientes, cumpliendo
                con la normativa vigente y según lo detallado en la Política de Privacidad.
                Además, acepto y confirmo haber leído los Términos y Condiciones establecidos
                por Jobfitter SpA para el uso de sus plataformas y sitios web, entendiendo la
                descripción de uso, recolección y disposición de Datos Personales detallada en ellos
            </Typography>
            {loading ? <CircularProgress /> : null}
          </Grid>
          <Container className={styles.options}>
            <Button
              className={styles.confirmBtn}
              onClick={handleSubmit}
              disabled={disableBtn}>
              Aplicar
            </Button>
          </Container>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        onClose={handleClearError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <MessageModal
        open={open}
        title="Enviar información"
        message="Felicidades, tu información ha sido enviada con éxito."
        showCancel={false}
        handleConfirm={handleReturn}
      />
      <Modal
        open={stateModalProgressBar}
        className={styles.modal}
        onClose={() => setStateModalProgressBar(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick>
        <div className={styles.paper}>
          <p>Por favor espere a que se termine de subir su archivo</p>
          <LinearWithValueLabel percentage={progress}></LinearWithValueLabel>
        </div>
      </Modal>
      <PrivacyModal open={modalOpen} setOpen={setStateModal} />
    </>
  )
}

export default Upload
