import React, { useCallback, useEffect, useRef, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Container } from '@material-ui/core'
import { get } from 'lodash'

import Loading from '../../../components/Loading'
import PageHeader from '../../../components/PageHeader'
import ProtectedPage from '../../../components/ProtectedPage'
import { SAURON_ROLE } from '../../../userRoles'

import Options from './components/Options'
import ProfilesList from './components/ProfilesList'

const COMPANIES = gql`
  query GetCompanies {
    companies {
      _id
      name
    }
  }
`

const CANDIDATES = gql`
  query FindCandidates(
    $input: FindCandidatesInput!
    $pagination: PaginationInput!
  ) {
    findCandidates(input: $input, pagination: $pagination) {
      data {
        _id
        fullName
        phone
        resume
        video
        applications {
          _id
          vacancyId {
            _id
          }
          vacancyTitle
          area
          seniorityLevel
          companyId {
            _id
          }
          companyName
          applicationId {
            _id
          }
          status
          applierName
          applierEmail
          expectedSalary
          keywords
          createdAt
        }
        createdAt
      }
      meta {
        hasNextPage
        hasPrevPage
        page
        take
        totalData
        totalPages
      }
    }
  }
`

const Candidates = () => {
  const { loading: companiesLoading, data: companiesData } = useQuery(COMPANIES)

  const [companies, setCompanies] = useState([])
  const [profiles, setProfiles] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    take: 10,
    sortField: 'createdAt',
    sortOrder: 'DESC',
  })
  const [filters, setFilters] = useState({
    name: '',
    companyId: '',
    expectedSalary: -1,
    area: 'ALL',
    seniorityLevel: '',
    keywords: [],
  })

  const previousFiltersRef = useRef(filters)

  const [getCandidates, { data, loading }] = useLazyQuery(CANDIDATES, {
    fetchPolicy: 'network-only',
  })

  const fetchCandidates = useCallback(
    (input, paginationParams) => {
      console.log(input)
      getCandidates({
        variables: {
          input,
          pagination: paginationParams,
        },
      })
    },
    [getCandidates],
  )

  useEffect(() => {
    const candidatesData = get(data, 'findCandidates', {
      data: [],
      meta: {},
    })
    const companies = get(companiesData, 'companies', [])
    setProfiles(candidatesData.data)
    setCompanies(companies)
  }, [data, companiesData])

  const handleSearch = () => {
    const input = { ...filters }

    Object.keys(input).forEach(key => {
      if (
        input[key] === 'ALL' ||
        input[key] === '' ||
        (key === 'expectedSalary' && input[key] === -1) ||
        (key === 'keywords' && !input[key].length)
      )
        delete input[key]
    })

    if (
      JSON.stringify(previousFiltersRef.current) !== JSON.stringify(filters)
    ) {
      setPagination(prev => ({ ...prev, page: 1 }))
      previousFiltersRef.current = filters
    }
    fetchCandidates(input, pagination)
  }

  const handlePageChange = newPage => {
    const newPagination = { ...pagination, page: newPage }
    const input = { ...filters }
    Object.keys(input).forEach(key => {
      if (
        input[key] === 'ALL' ||
        input[key] === '' ||
        (key === 'expectedSalary' && input[key] === -1) ||
        (key === 'keywords' && !input[key].length)
      )
        delete input[key]
    })

    setPagination(newPagination)
    fetchCandidates(input, newPagination)
  }

  const handleItemsPerPageChange = newTake => {
    const newPagination = { ...pagination, take: newTake, page: 1 }

    const input = { ...filters }
    Object.keys(input).forEach(key => {
      if (
        input[key] === 'ALL' ||
        input[key] === '' ||
        (key === 'expectedSalary' && input[key] === -1) ||
        (key === 'keywords' && !input[key].length)
      )
        delete input[key]
    })

    setPagination(newPagination)
    fetchCandidates(input, newPagination)
  }

  return (
    <ProtectedPage allowedRoles={[SAURON_ROLE]}>
      <PageHeader title="Buscador unificado de Candidatos" type="candidates" />
      <Container>
        <Options
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearch}
          companies={companies}
          loading={loading}
          take={pagination.take}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
        {loading || companiesLoading ? (
          <Loading />
        ) : (
          <ProfilesList
            profiles={profiles}
            meta={data?.findCandidates?.meta}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </Container>
    </ProtectedPage>
  )
}

export default Candidates
