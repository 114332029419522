import { gql } from '@apollo/client'

export const APPLICATIONS = gql`
  query Applications(
    $vacancyId: ID!
    $pagination: PaginationInput
    $filters: ApplicationFilterInput
  ) {
    applications(
      vacancyId: $vacancyId
      pagination: $pagination
      filters: $filters
    ) {
      data {
        _id
        applierName
        applierPhone
        applierExpectedSalary
        applierEmail
        status
        resume
        video
        createdAt
        notes {
          user
          message
          time
        }
        interviews {
          user
          message
          time
        }
        videoResults {
          vote
          userid
        }
        previousApplications {
          status
          vacancy {
            _id
            title
          }
        }
        answers {
          question
          answer
        }
        ludwika {
          conscientiousness {
            value
          }
          extraversion {
            value
          }
          neuroticism {
            value
          }
          openness {
            value
          }
          agreeableness {
            value
          }
        }
      }
      meta {
        totalData
        totalPages
        page
        take
        hasNextPage
        hasPrevPage
      }
    }
  }
`

export const VACANCY = gql`
  query Vacancy($_id: ID!) {
    vacancy(_id: $_id) {
      _id
      title
      area
      seniorityLevel
      status
      location
      maxIncome
      description
      createdAt
      updatedAt
      applicationsToVote {
        _id
        applierName
        video
        status
        videoResults {
          vote
          userid
        }
      }
      questions {
        text
        type
        options
      }
      company {
        _id
      }
      users {
        _id
      }
    }
  }
`
