import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Container } from '@material-ui/core'
import { get } from 'lodash'

import Loading from '../../../../components/Loading'
import PageHeader from '../../../../components/PageHeader'
import { useDebounce } from '../../../../hooks/useDebounce'

import Options from './components/Options'
import VacanciesList from './components/VacanciesList'

const VACANCIES = gql`
  query GetVacancies(
    $pagination: PaginationInput!
    $filters: VacancyFilterInput!
  ) {
    vacancies(pagination: $pagination, filters: $filters) {
      data {
        _id
        title
        location
        status
        area
        seniorityLevel
        applicationsCount
        createdAt
        updatedAt
      }
      meta {
        hasNextPage
        hasPrevPage
        page
        take
        totalData
        totalPages
      }
    }
  }
`

const DELETE_VACANCY = gql`
  mutation DeleteVacancy($_id: ID!) {
    deleteVacancy(_id: $_id) {
      _id
    }
  }
`

const DEFAULT_PAGINATION = {
  page: 1,
  take: 5,
  sortField: 'createdAt',
  sortOrder: 'DESC',
}

const DEFAULT_FILTERS = {
  type: 'ALL',
  search: '',
  area: 'ALL',
  seniorityLevel: 'ALL',
}

const Main = () => {
  const history = useHistory()
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  // Use debounced search term
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const [getVacancies, { data, loading }] = useLazyQuery(VACANCIES, {
    fetchPolicy: 'cache-and-network',
  })

  const [deleteVacancy, { loading: loadingDelete }] = useMutation(
    DELETE_VACANCY,
  )

  const cleanFilters = useCallback(filtersInput => {
    const cleanedFilters = { ...filtersInput }
    Object.keys(cleanedFilters).forEach(key => {
      if (cleanedFilters[key] === 'ALL' || cleanedFilters[key] === '')
        delete cleanedFilters[key]
    })
    return cleanedFilters
  }, [])

  const fetchVacancies = useCallback(
    (filtersInput, paginationParams) => {
      const cleanedFilters = cleanFilters(filtersInput)

      getVacancies({
        variables: {
          pagination: paginationParams,
          filters: cleanedFilters,
        },
      })
    },
    [getVacancies, cleanFilters],
  )

  useEffect(() => {
    setPagination(prev => ({ ...prev, page: 1 }))
  }, [filters.type, filters.area, filters.seniorityLevel])

  useEffect(() => {
    setFilters(prev => ({ ...prev, search: debouncedSearchTerm }))
  }, [debouncedSearchTerm])

  useEffect(() => {
    fetchVacancies(filters, pagination)
  }, [filters, pagination, fetchVacancies])

  const handleDeleteVacancy = async (id, onComplete) => {
    try {
      await deleteVacancy({
        variables: { _id: id },
        update: cache => {
          cache.evict({ id: `Vacancy:${id}` })
          cache.gc()
        },
      })
      if (onComplete) onComplete()
      fetchVacancies(filters, pagination)
    } catch (error) {
      console.error('Error deleting vacancy:', error)
    }
  }

  const handlePageChange = newPage => {
    setPagination(prev => ({ ...prev, page: newPage }))
  }

  const handleItemsPerPageChange = newTake => {
    setPagination(prev => ({ ...prev, take: newTake, page: 1 }))
  }

  const handleFiltersChange = newFilters => {
    setFilters(prev => ({ ...prev, ...newFilters }))
  }

  const vacancies = get(data, 'vacancies.data', [])
  const meta = get(data, 'vacancies.meta', {})

  return (
    <>
      <PageHeader
        title="Mis vacantes"
        button={{
          text: 'Publicar vacante',
          handleClick: () => history.push('/admin/vacancies/create'),
        }}
      />
      <Container>
        <Options
          filters={filters}
          setFilters={handleFiltersChange}
          take={pagination.take}
          onItemsPerPageChange={handleItemsPerPageChange}
          setSearchTerm={setSearchTerm}
        />
        {loading ? (
          <Loading />
        ) : (
          <VacanciesList
            take={pagination.take}
            vacancies={vacancies}
            meta={meta}
            search={filters.search}
            type={filters.type}
            area={filters.area}
            seniorityLevel={filters.seniorityLevel}
            loading={loadingDelete}
            handleDelete={handleDeleteVacancy}
            reload={() => fetchVacancies(filters, pagination)}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </Container>
    </>
  )
}

export default Main
